import React, { useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import logoImage from '../assets/images/logo.png';
import { device } from '../utils/Brekapoints';
import MenuIcon from '../assets/icons/burger-icon.svg';
import CloseMenuIcon from '../assets/icons/nav-close.svg';
import LinkExternal from '../assets/icons/link-external.svg';

const NavWrapper = styled.div`
  width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media ${device.mobile} {
    width: 100%;
  }
`;

const LogoWrapper = styled.div`
  line-height: 0;

  a {
    padding: 0;
    display: inline-block;

    &:hover {
      background-color: transparent;
    }
  }

  img {
    padding-top: 8px;
    height: 40px;

    @media ${device.mobile} {
      padding-top: 14px;
      padding-left: 10px;
      height: 36px;
    }
  }
`;

const MenuWrapper = styled.button`
  display: none;
  background: none;
  border: none;
`;

const NavLinks = styled.ul`
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  list-style: none;
  font-size: 1.6rem;
  font-weight: 500;
  font-display: optional;

  .link-external img {
    height: 14px;
    filter: invert(91%) sepia(92%) saturate(33%) hue-rotate(202deg)
      brightness(107%) contrast(100%);
    padding-left: 5px;
  }

  @media ${device.tablet} {
    display: none;
  }
`;

const NavLink = styled.li`
  a {
    padding: 20px;
    text-transform: uppercase;
    font-size: 18px;
    font-display: optional

    &:hover {
      background-color: var(--navy);
    }
  }
`;

const NavStyles = styled.nav`
  background-color: var(--dark-blue);

  @media ${device.tablet} {
    ${MenuWrapper} {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      height: 61px;

      img {
        height: 40px;
        padding-top: 6px;
      }
    }

    ${NavWrapper} {
      width: auto;
    }
  }

  &.mobile-active {
    @media ${device.tablet} {
      height: 100%;
      width: 100%;
      position: fixed;
      z-index: 100;
      top: 0;
      left: 0;
      overflow-x: hidden;
      transition: 0.5s;

      ${LogoWrapper} {
        display: none;
      }

      ${NavWrapper} {
        flex-direction: column;
      }

      ${NavLinks} {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 0;
      }

      ${NavLink} {
        border-bottom: 2px solid var(--white);
        a {
          display: block;

          &:after {
            content: '>';
            font-weight: 700;
            font-size: 3rem;
            position: absolute;
            right: 10px;
          }

          &:hover {
            background: var(--navy);
          }
        }
      }
    }
  }
`;

export default function Nav() {
  const { headerLinks } = useStaticQuery(graphql`
    query {
      headerLinks: allSanitySiteSettings {
        nodes {
          headerLinks {
            _key
            name
            link
          }
        }
      }
    }
  `);

  const displayHeaderLinks = headerLinks.nodes[0].headerLinks;
  const [isMobileNavActive, setMobileNavActive] = useState(false);
  const handleMobileNavToggle = () => {
    setMobileNavActive(!isMobileNavActive);
  };
  return (
    <NavStyles className={isMobileNavActive ? 'mobile-active' : false}>
      <NavWrapper>
        <LogoWrapper>
          <Link to="/">
            <img src={logoImage} height={40} width={167} alt="logo" />
          </Link>
        </LogoWrapper>
        <MenuWrapper onClick={handleMobileNavToggle}>
          <img src={isMobileNavActive ? CloseMenuIcon : MenuIcon} alt="Menu" />
        </MenuWrapper>
        <NavLinks>
          {displayHeaderLinks.map((headerLink) => (
            <NavLink key={headerLink._key}>
              {headerLink.link.charAt(0) === '/' ? (
                <Link
                  to={headerLink.link}
                  onClick={isMobileNavActive ? handleMobileNavToggle : null}
                  className="link-white"
                >
                  {headerLink.name}
                </Link>
              ) : (
                // eslint-disable-next-line react/jsx-no-target-blank
                <a
                  href={headerLink.link}
                  className="link-white link-external"
                  onClick={isMobileNavActive ? handleMobileNavToggle : null}
                  target="_blank"
                >
                  {headerLink.name}
                  <img src={LinkExternal} alt="Open in a new tab" />
                </a>
              )}
            </NavLink>
          ))}
        </NavLinks>
      </NavWrapper>
    </NavStyles>
  );
}
